module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":300},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"lep.mk.ua","short_name":"lep.mk.ua","start_url":"/","theme_color":"#ffffff","background_color":"#ffffff","display":"standalone","icon":"src/assets/favicon/favicon.png","icons":[{"src":"src/assets/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png","purpose":"any"},{"src":"src/assets/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png","purpose":"any"},{"src":"src/assets/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"src/assets/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"src/assets/favicon/favicon-maskable.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3bfd7066aaaf5dccbb31b528300a72db"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
